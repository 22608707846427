import React, { useEffect, useRef, useState } from 'react';
import Breakfast from './Breakfast';
import './Menu.css';
import Snacks from './Snacks';
import EveningSnacks from './EveningSnacks';
import Lunch from './Lunch';
import Dinner from './Dinner';

const Menu = () => {
  const [activeSection, setActiveSection] = useState(null);
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null, // Observe within the viewport
      threshold: 0.5, // Trigger when 50% of the section is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id); // Update the active section
        }
      });
    }, observerOptions);

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);


    
  const [selectedCarb, setSelectedCarb] = useState('');
  const [selectedProtein, setSelectedProtein] = useState('');
  const [selectedParatha, setSelectedParatha] = useState('');
  const [selectedGreens, setSelectedGreens] = useState('');
  const [selectedMeal, setSelectedMeal] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showMealOptions, setShowMealOptions] = useState(false);
  const [selectedDayMeal, setSelectedDayMeal] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayDetails, setSelectedDayDetails] = useState(null);


  const mealListRef = useRef(null);
  useEffect(() => {
    const ref = mealListRef.current;
    if (ref) {
      const handleScroll = () => {
        if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
          ref.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };
      ref.addEventListener('scroll', handleScroll);
      return () => {
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);


  return (
    <div>
     <div className="menu-container">
        <div className="title">
          <h1>Menu Cards</h1>
        </div>
         {/* calendar */}
         <div className="day-selector days-select daysselect">
          <div className="days-container days-contain">
            {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
              <button
                key={day}
                // onClick={() => handleDayClick(day)}
                className={`dayButton ${selectedDay === day ? 'active' : ''}`}
              >
                Day {day}
              </button>
            ))}
          </div>
         
        </div>
          
          <div className="allmenucard w-[500px] m-auto mt-20 overflow-y-auto h-[500px] pb-8">
          {/* {[
        { id: 'Breakfast', Component: <Breakfast /> },
        { id: 'Snacks', Component: <Snacks /> },
        { id: 'Lunch', Component: <Lunch /> },
        { id: 'EveningSnacks', Component: <EveningSnacks /> },
        { id: 'Dinner', Component: <Dinner /> },
      ].map(({ id, Component }, index) => (
        <div
          key={id}
          id={id}
          ref={(el) => (sectionsRef.current[index] = el)}
          className={`transition-colors ${
            activeSection === id ? 'active-section' : 'inactive-section'
          }`}
        >
          {Component}
        </div>
      ))} */}

          <Breakfast />
          <Snacks />
          <Lunch />
          <EveningSnacks />
          <Dinner />
        </div>
     </div>
    </div>
  )
}

export default Menu;